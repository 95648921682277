<template>
    <!-- Features -->
    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-24 mx-auto">
        <!-- Grid -->
        <div class="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
            <div class="flex justify-center gap-4 ml-2">
                <img
                    class="rounded-xl w-1/2"
                    src="../assets/foto_died_website.jpg"
                    alt="Diederik"
                />
                <img
                    class="rounded-xl w-1/2"
                    src="../assets/foto_sophron_website.jpg"
                    alt="Sophron"
                />
            </div>
            <!-- End Col -->

            <div class="mt-5 sm:mt-10 lg:mt-0">
                <div class="space-y-6 sm:space-y-8">
                    <!-- Title -->
                    <div class="space-y-2 md:space-y-4">
                        <h2
                            class="font-bold text-3xl lg:text-4xl font-header text-primary dark:text-gray-200 text-left"
                        >
                            About Waive
                        </h2>

                        <p class="text-secondary font-body text-left">
                            Hi, we’re Diederik and Sophron, friends united by a
                            vision where AI is in every fabric of daily
                            business. The immense speed of AI development
                            sparked Waive, our platform to navigate businesses
                            through AI's potential with clarity and confidence.
                        </p>
                        <h3
                            class="font-bold font-header text-header text-lg lg:text-xl text-primary dark:text-gray-200 text-left"
                        >
                            Our background
                        </h3>
                        <p class="text-secondary font-body text-left">
                            With Sophron's 10 year expertise in AI development
                            and Diederik's strategic foresight in business
                            growth, we stand at the intersection of innovation
                            and practicality. Our paths crossed over a shared
                            ambition: to harness the
                            science-fiction-turned-reality of AI and channel it
                            into real-world business success.
                        </p>
                        <h3
                            class="font-bold font-header text-lg lg:text-xl text-primary dark:text-gray-200 text-left"
                        >
                            What sets us apart
                        </h3>
                        <p class="text-secondary font-body text-left">
                            At Waive, we blend in-depth AI expertise with a
                            personalized approach. We don't just integrate AI;
                            we tailor to your business, ensuring it works for
                            you. <br /><br />It's our commitment to not only
                            adapt to AI's advancements but to make them
                            intuitively work for your growth and innovation.
                        </p>
                    </div>
                    <!-- End Title -->
                </div>
            </div>
            <!-- End Col -->
        </div>
        <!-- End Grid -->
    </div>
    <!-- End Features -->
</template>

<script>
export default {
    name: "AboutUsSection",
    // You can add JavaScript logic here if needed
};
</script>

<style scoped></style>
